import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, ListGroup, Row, Tab } from "react-bootstrap"
import { siteMetadata } from "../../../../gatsby-config"
import { syspharListGroupEn } from "../../../data/syspharListGroup"
import { getENCitation } from "../../../utils"
import ListGroupWikiItem from "../../listgroup/ListGroupWikiItem"

export default function SyspharTabs() {
  return (
    <section className="container" id="wiki">
      <Tab.Container defaultActiveKey="#00">
        <Row>
          <Col sm={4}>
            <ListGroup id="list-group">
              <ListGroupWikiItem array={syspharListGroupEn} />
            </ListGroup>
          </Col>
          <Col sm={1}></Col>
          <Col sm={6}>
            <Tab.Content className="mt-3 mt-md-0">
              <Tab.Pane eventKey="#00">
                <h3 className="fw-bold">Welcome</h3>
                <p>Hello, pharmacologists!</p>
                <p>
                  Welcome to SysPhar Wiki Systematic Reviews in Pharmacology.
                </p>
                <p>
                  This SysPhar Guide contains helpful information, links,
                  articles, resources, and tools to conduct your systematic
                  review and meta-analysis.
                </p>
                <p>
                  Browse sections to learn how to plan, conduct, and complete a
                  systematic review, meta-analysis, or living systematic review.
                </p>
                <p>
                  Use the index bar on the left side of the screen to navigate
                  through the steps outlined in our guide.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#10">
                <h3 className="fw-bold">1 Systematic Review and Meta-analysis</h3>
                <h3>1.1 What is Systematic Review?</h3>
                <p>
                  <p>
                    Systematic reviews are literature reviews that identify,
                    obtain, filter, evaluate, and synthesize scientific studies
                    to answer a research question. These reviews use transparent
                    methods and, as far as possible, are based on technically
                    reasoned and impartial decisions, and are therefore
                    reproducible.
                  </p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_1.png"
                    alt="FIG_1"
                  />
                  <figcaption className="figure-caption">
                    Figure 1. Systematic reviews, with or without meta-analysis,
                    are a type of secondary literature because they gather data
                    from the primary literature.
                  </figcaption>
                </p>
                <p>
                  Suggested references:{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://pubmed.ncbi.nlm.nih.gov/9054282/"
                  >
                    Cook et al. (1997)
                  </a>
                  ;
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://pubmed.ncbi.nlm.nih.gov/24479036/"
                  >
                    Gopalakrishnan and Ganeshkumar (2013)
                  </a>
                  ;
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://pubmed.ncbi.nlm.nih.gov/29169792/"
                  >
                    Delgado-Rodríguez and Sillero-Arenas (2018)
                  </a>
                  .
                </p>
                <p>
                  *Nota: uma revisão sistemática pode ou não ser seguida de uma
                  meta-análise.
                </p>

                <h3>1.2 What is Meta-analysis?</h3>
                <p>
                  Meta-analysis is a statistical method that combines the
                  quantitative results of two or more independent primary
                  studies, synthesizing them into a single result.
                </p>

                <p>
                  Suggested references:
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3049418/"
                  >
                    Haidich (2010)
                  </a>
                  ,
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://pubmed.ncbi.nlm.nih.gov/24099992/"
                  >
                    Vesterinen et al. (2014)
                  </a>
                  ,
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://academic.oup.com/bja/article/117/4/428/2410577"
                  >
                    Møller and Myles (2016)
                  </a>
                  , and
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://pubmed.ncbi.nlm.nih.gov/28891724/"
                  >
                    Wormald and Evans (2018)
                  </a>
                  .
                </p>

                <h3>1.3 What is Systematic Review and Living Meta-Analysis?</h3>
                <p>
                  Systematic reviews and meta-analyses can quickly become
                  obsolete. A living systematic review and living meta-analysis
                  is a systematic review which is continually updated,
                  incorporating new relevant evidence as it becomes available
                  (from
                  <a target="_blank" rel="noopener noreferrer" href="https://community.cochrane.org/review-production/production-resources/living-systematic-reviews">
                    Living Systematic Review
                  </a>
                  ).
                </p>
                <p>
                  Suggested references:
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3928029/"
                  >
                    Elliott et al. (2014)
                  </a>
                  ,
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4955793/"
                  >
                    Garner et al. (2016)
                  </a>
                  ,
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://pubmed.ncbi.nlm.nih.gov/28912002/"
                  >
                    Elliott et al. (2017)
                  </a>
                  , and
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://pubmed.ncbi.nlm.nih.gov/34550587/"
                  >
                    Simmonds et al. (2022)
                  </a>
                  .
                </p>
                <h3>1.4 Systematic Reviews and Meta-Analyses in Pharmacology</h3>
                <p>
                  In the context of pharmacology, individual studies are not
                  always sufficient to provide reliable information for or
                  against the use of a drug in an experiment, a project, or even
                  in the development of a drug.
                </p>
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/en/sysphar/FIG_2.png"
                  alt="FIG_2"
                />
                <figcaption className="figure-caption">
                  Figure 2. Systematic reviews and meta-analyses in pharmacology
                  can provide useful information for the discovery and
                  development of new drugs.
                </figcaption>

                <p>
                  Thus, with or without meta-analysis, systematic reviews are
                  helpful in gathering, combining, and synthesizing evidence
                  relevant to the decision-making process in a single study.
                </p>
                <p>
                  Although abundant, the pharmacological literature is
                  heterogeneous, and primary studies generally have small
                  samples. Therefore, meta-analyses can be helpful in
                  pharmacology. In addition, meta-analyses can help identify the
                  beneficial or toxic effects of drugs unnoticed in primary
                  studies.
                </p>
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/en/sysphar/FIG_3.png"
                  alt="FIG_3"
                />
                <figcaption className="figure-caption">
                  Figure 3. Systematic reviews are methods that help to find,
                  filter and evaluate the quality of primary studies immersed in
                  a vast literature, while meta-analyses are statistical methods
                  that allow the "reanalysis" of the sets of data extracted from
                  primary studies forming a single result.
                </figcaption>

                <p>
                  Systematic reviews, with or without meta-analysis, can also be
                  used to understand the mechanisms of action of a drug or a
                  pharmacological class, identify gaps in knowledge, or study
                  the effectiveness and safety of a drug or a pharmacological
                  class.
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_4.png"
                    alt="FIG_4"
                  />
                  <figurecaption className="figure-caption">
                    Figure 4. Systematic Reviews and Meta-analyses in
                    Pharmacology
                    <br />
                    Legend: Adapted figure of Bolzan and Lino de Oliveira
                    (2021).
                  </figurecaption>
                </p>

                <p>
                  Suggested references:
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.sciencedirect.com/science/article/abs/pii/S0163725820300437"
                  >
                    Lino de Oliveira et al. (2020)
                  </a>
                  ;
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5568412/"
                  >
                    Hesen et al. (2017)
                  </a>
                  ;
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8268344/"
                  >
                    Al-Waeli et al. (2021)
                  </a>
                  . See systematic reviews in pharmacology in the
                  <Link to="/en/portfolio-and-initiatives">
                    "Portfolio &amp; Initiatives"
                  </Link>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#20">
                <h3 className="fw-bold">2 General plan of systematic reviews and meta-analyses</h3>
                <h3>2.1 Planning, implementation, publication, and maintenance</h3>
                <p>
                  <p>
                    At SysPhar, the systematic reviews and meta-analysis is
                    divided into chronologically organized phases as shown in{" "}
                    <strong>Figure 5</strong>. Phase 0 is the period before
                    starting. Phases 1 to 5 are for elaborating the research
                    question, elaborating the protocol, registering the
                    protocol, implementing the protocol, and publishing the
                    results. Phase 6 is maintaining the systematic review and
                    living meta-analysis.
                  </p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_5.png"
                    alt="FIG_5"
                  />
                  <figcaption className="figure-caption">
                    Figure 6. General plan for systematic reviews and
                    meta-analyses*
                  </figcaption>
                  <p>
                    *NOTE: Not every systematic review needs to be followed by a
                    meta-analysis. Not every systematic review and meta-analysis
                    needs to be conducted. Every pharmacologist must decide
                    according to their own research area.
                  </p>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#30">
                <h3 className="fw-bold">3 Phase 0: Before starting</h3>
                <h3>3.1 Relevance of the study</h3>
                <p>
                  To save time and effort, we suggest that pharmacologists
                  conduct a preliminary analysis of the context of a systematic
                  review and meta-analysis in pharmacology by answering the
                  following questions:{" "}
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_6.png"
                    alt="FIG_6"
                  />
                  <figcaption className="figure-caption">
                    Figure 7. Phase 0: Analyze the relevance of doing this
                    review in Pharmacology
                  </figcaption>
                </p>
                <p>
                  If you answered "yes" to questions 1–5 and "no" to question 6,
                  it is possible that your review has scientific, technological,
                  and originality relevance, so prepare your team!
                </p>
                <p>
                  If you have also answered "yes" to question 6, that is, a
                  review of your interest is already in progress, consider
                  contacting the review team for possible collaboration.
                </p>
                <p>
                  *NOTE: To verify that the topic has been previously revised, a
                  brief search on virtual bases relevant to Pharmacology (e.g.,
                  Medline via
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://pubmed.ncbi.nlm.nih.gov/"
                  >
                    PubMed 
                    <br/>
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.scopus.com/home.uri"
                  >
                    Scopus,
                    <br/>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://access.clarivate.com/login?app=wos&amp;alternative=true&amp;shibShireURL=https:%2F%2Fwww.webofknowledge.com%2F%3Fauth%3DShibboleth&amp;shibReturnURL=https:%2F%2Fwww.webofknowledge.com%2F%3Fmode%3DNextgen%26action%3Dtransfer%26path%3D%252Fwos%252Fwoscc%252Fbasic-search%26DestApp%3DUA&amp;referrer=mode%3DNextgen%26path%3D%252Fwos%252Fwoscc%252Fbasic-search%26DestApp%3DUA%26action%3Dtransfer&amp;roaming=true">
                    Web of Science 
                    <br/>
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.embase.com/?phase=continueToApp#search"
                  >
                    Embase
                  </a>
                  ) can be conducted. In addition, consider prepress platforms
                  such as
                  <a target="_blank" rel="noopener noreferrer" href="https://www.biorxiv.org/">
                    bioRxiv
                  </a>
                  ,
                  <a target="_blank" rel="noopener noreferrer" href="https://www.medrxiv.org/">
                    medRxiv
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/dashboard">
                    OSF
                  </a>
                  , or ongoing systematic review deposits such as
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42014009988">
                    PROSPERO
                  </a>
                  .
                </p>
                <p>
                  In the planning, implementation, publication, and maintenance
                  of a systematic review and meta-analysis in pharmacology, an
                  ideal team would be formed by:
                </p>
                <h3>3.2 Preparing the team to begin</h3>
                <p>
                  <ul>
                    <li>
                      Pharmacologists: selection of the theme and preparation of
                      the question of the relevant review in pharmacology;
                      preparation of the protocol (e.g., helping to identify the
                      relevant aspects for the scope of the search and selection
                      of studies, as well as a meta-analysis of the data).
                    </li>
                    <li>
                      Librarians or methodologists specialized in systematic
                      reviews of the literature: preparation of the protocol
                      (e.g., helping to create search strategies in the
                      literature); recommendation of software, tools, and
                      approaches to search and select relevant literature to
                      answer the review question.
                    </li>
                    <li>
                      Statisticians or methodologists specialized in
                      meta-analysis: preparation of the protocol (e.g., helping
                      to select the types of calculations of effect size and
                      statistical model according to the outcomes of interest),
                      recommendation of software, tools, and approaches to
                      analyze the data.
                    </li>
                    <li>
                      Reviewers: realization of the less-specialized stages of
                      the process (e.g., selection of studies applying
                      pre-established criteria, extraction of data according to
                      the protocol, and tabulation of data extracted from the
                      studies). Even inexperienced reviewers can contribute to
                      the implementation steps of the review protocol under the
                      supervision of more experienced team members. These
                      reviewers can be recruited during this work.
                    </li>
                  </ul>
                </p>
                <p>
                  It can be helpful to create specific roles for each team
                  member. More than one person can play each role, and each
                  person can play more than one role simultaneously:
                </p>
                <p>
                  <ul>
                    <li>
                      Leader:responsible for managing team activities and
                      reviewing documents**.
                    </li>
                    <li>
                      Independent reviewer 1:responsible for applying study
                      eligibility criteria, extracting, and tabulating data from
                      studies included in the review independently of reviewer 2
                    </li>
                    <li>
                      Independent reviewer 2:responsible for applying the
                      eligibility criteria of the studies, extracting, and
                      tabulating the data of the studies included in the review
                      independently of reviewer 1
                    </li>
                    <li>
                      Reviewer 3 (the conciliator):responsible for reconciling
                      the discrepancies between independent reviewers 1 and 2.
                    </li>
                  </ul>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_7.png"
                    alt="FIG_7"
                  />
                  <figcaption className="figure-caption">
                    Figure 8. Prepare a minimal team
                  </figcaption>
                  <p>
                    *NOTE: At the beginning of the review process, it is
                    essential to record and store all review-related documents
                    in one sharing location (e.g.,
                    <a target="_blank" rel="noopener noreferrer"

                      href="https://osf.io/dashboard"
                    >
                      OSF
                    </a>
                    ,{" "}
                    <a target="_blank" rel="noopener noreferrer"

                      href="https://www.google.com/intl/pt-BR/drive/"
                    >
                      Google Drive
                    </a>
                    ,
                    <a target="_blank" rel="noopener noreferrer" href="https://www.microsoft.com/pt-br/microsoft-365/onedrive/online-cloud-storage">
                      OneDrive
                    </a>
                    ) for the entire team to have access.
                  </p>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#40">
                <h3 className="fw-bold">4 Phase 1: Drafting the review question</h3>
                <p>
                  The definition of the research question will guide all
                  decisions in the next phases of a systematic review and
                  meta-analysis. Often, research questions in pharmacology
                  assume similar structures: <em></em>
                </p>
                <p>
                  <ul>
                    <li>
                      {" "}
                      "Is drug X effective in curing/improving a Y condition in
                      a specific type of experiment/test?";
                    </li>
                    <li>
                      {" "}
                      "Is there a relationship between dose-response between
                      drug X and answer Y?";
                    </li>
                    <li>
                      {" "}
                      "Is a population (such as rats, mice, humans, and in vitro
                      preparation) susceptible to the effects of drug X?";
                    </li>
                    <li>
                      {" "}
                      “Is a subpopulation of the sample (such as species,
                      lineage, and sex) more susceptible to the effects of drug
                      X?".
                    </li>
                  </ul>
                  <p>
                    Thus, mnemonic tools were developed to aid in elaborating a
                    clear, objective research question that meets the interests
                    of the review.
                  </p>
                </p>
                <h3>4.1 PICO Tool </h3>
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/en/sysphar/FIG_8.png"
                  alt="FIG_8"
                />
                <figcaption className="figure-caption">
                  Figure 9. Elaborating the review question
                </figcaption>
                <p>
                  The{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://mfr.osf.io/render?url=https://osf.io/t5w8y/?direct%26mode=render%26action=download%26mode=render"
                  >
                    PICO
                  </a>
                  tool (P: patient or population or problem; I: intervention; C:
                  comparison or control; O: outcome), developed to review
                  randomized controlled clinical trials, is also suitable for
                  reviewing nonclinical studies in pharmacology.
                </p>
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/en/sysphar/TAB_1.png"
                  alt="TAB_1"
                />
                <figcaption className="figure-caption">
                  Table 1. PICO tool: definitions and examples in pharmacology
                </figcaption>
                <figcaption className="figure-caption">
                  Reference: adapted from Bolzan and Lino de Oliveira (2022).{" "}
                </figcaption>
                <p>
                  NOTE: Note that in the context of Pharmacology, the term
                  "Intervention" is often synonymous with "treatment with drug
                  X."
                </p>
                <p>
                  Suggested references:
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6148624/"
                  >
                    Eriksen and Frandsen (2018)
                  </a>
                  and
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1904193/"
                  >
                    Schardt et al. (2007)
                  </a>
                  .
                </p>
                <h3>4.2 Other mnemonic tools </h3>
                <p>
                  Other mnemonic tools can be used in pharmacology and are
                  helpful in asking questions about specific types of research,
                  such as <em>in vitro, in vivo, </em>
                  <em>and ex vivo</em>.
                </p>
                <p>
                  Examples of variations to the PICO tool, PICOC (PICO plus
                  Contexto), PICOT (PICO plus Time), SPICE (S: Setting or
                  experimental context; P: Population; I: Intervention; C:
                  Comparison; E: Evaluation or Evaluation); and SPIDER (S -
                  Sample or Sample of interest; P - Phenomenon or Phenomenon of
                  Interest; D - Experimental Design; E - Evaluation or
                  Evaluation; R - Research type).
                </p>
                <p>
                  Suggested reference:
                  <a target="_blank" rel="noopener noreferrer" href="https://journals.sagepub.com/doi/10.1177/1049732312452938?url_ver=Z39.88-2003&amp;rfr_id=ori:rid:crossref.org&amp;rfr_dat=cr_pub%20%200pubmed"
                  >
                    Cooke et al. (2012)
                  </a>
                  and{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://parsif.al/help/about-the-picoc/"
                  >
                    Wohlin et al. (2012)
                  </a>
                  .
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#50">
                <h3 className="fw-bold">5 Phase 2: Drafting the revision protocol</h3>
                <h3>5.1 Protocol templates</h3>
                <p>
                  <p>
                    The protocol contains a description of all the methods
                    selected to answer the research question prepared in the
                    first phase of the process.
                  </p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_9.png"
                    alt="FIG_9"
                  />
                  <figcaption className="figure-caption">
                    Figure 9. Phase 2: Review Protocol
                  </figcaption>
                </p>
                <p>
                  Specialized organizations offer suggestions for protocols
                  following good practices for systematic review and
                  meta-analysis of studies in general ({" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="http://www.prisma-statement.org/Extensions/Protocols"
                  >
                    PRISMA
                  </a>
                  ), humans (
                  <a target="_blank" rel="noopener noreferrer" href="https://training.cochrane.org/handbook/current/chapter-01#section-1-5">
                    COCHRANE
                  </a>
                  ), and animals (
                  <a target="_blank" rel="noopener noreferrer" href="https://camaradesbrasil.bio.br/portfolio-e-iniciativas"
                  >
                   CAMARADES 
                   <br/>
                  </a>
                  , 
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://onlinelibrary.wiley.com/doi/epdf/10.1002/ebm2.7"
                  >
                    SYRCLE
                  </a>
                  ).
                </p>
                <p>
                  We suggest using the structured Form SYRCLE for the
                  elaboration of a complete protocol. The form in .docx can be
                  downloaded{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/58ftv/">
                    HERE
                  </a>
                  .
                </p>
                <p>
                  See examples of complete protocols of systematic reviews and
                  meta-analyses in pharmacology (
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42019125868">
                    <em>Zameer et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://onlinelibrary.wiley.com/action/downloadSupplement?doi=10.5694%2Fmja2.50992&amp;file=mja250992-sup-0001-Supinfo.pdf">
                    <em>Abdel</em>
                    <em> et al., 2021</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42018109097">
                    <em>Ma et al., 2018</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020184547">
                    <em>Nibber et al., 2020</em>
                  </a>
                  <em>).</em>
                </p>

                <p>
                  <strong>5.2. Planning the search strategy</strong>
                </p>
                <p>
                  The protocol should have a detailed plan for obtaining
                  relevant publications from the bibliographic databases called
                  "search strategy." *
                </p>
                <p>
                  A search strategy consists of describing the lists of relevant
                  search terms (keywords) combined with Boolean operators (e.g.,
                  AND, OR) and syntaxes used in searches conducted in different
                  bibliographic databases.
                </p>
                <p>
                  See search strategy examples using terms related to population
                  (P), intervention (I), and outcome (O) of interest or their
                  combination.
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_10.png"
                    alt="FIG_10"
                  />
                  <figcaption className="figure-caption">
                    Figure 10. Example of search strategy in Pubmed
                  </figcaption>
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_11.png"
                    alt="FIG_11"
                  />
                  <figcaption className="figure-caption">
                    Figure 11. Example of search strategy in the Scopus
                    bibliographic database
                  </figcaption>
                </p>
                <p>
                  <em>
                    Examples of protocols presenting search strategies in
                    pharmacology (
                  </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42019125868">
                    <em>Zameer et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://onlinelibrary.wiley.com/action/downloadSupplement?doi=10.5694%2Fmja2.50992&amp;file=mja250992-sup-0001-Supinfo.pdf">
                    <em>Abdel</em>
                    <em> et al., 2021</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42018109097">
                    <em>Ma et al., 2018</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020184547">
                    <em>Nibber et al., 2020</em>
                  </a>
                  <em>). </em>
                </p>
                <p>
                  <em>*NOTE: </em>
                </p>
                <p>
                  <em>
                    1-Small searches in the bibliographic databases, called{" "}
                  </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/p9uvk/">
                    <em>pilot studies</em>
                  </a>
                  <em>
                    , help to establish the final search strategy presented in
                    the protocol;
                  </em>
                </p>
                <p>
                  <em>
                    2- research on different bibliographic bases combined will
                    provide a more comprehensive review;
                  </em>
                </p>
                <p>
                  <em>3- different search engines operate differently; </em>
                </p>
                <p>
                  <em>
                    4- access to some bibliographic bases requires payment or
                    institutional login.
                  </em>
                </p>
                <p>
                  <strong>5.3. Planning the selection strategy</strong>
                </p>
                <p>
                  The authors describe how relevant studies will be selected
                  from publications retrieved from the bibliographic databases
                  in this protocol item.
                </p>
                <p>
                  The selection strategy includes planning screening phases
                  (title and/or summary and/or full text), the number of
                  independent reviewers, and the conciliators involved. Other
                  decisions include contacting the corresponding authors when
                  there is a lack of information.
                </p>
                <p align="center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_12.png"
                    alt="FIG_12"
                  />
                  <figcaption className="figure-caption">
                    Figure 12. Selection of studies relevant to the review
                  </figcaption>
                  <strong>Figure .</strong>
                  Selection of studies relevant to the review
                </p>
                <p>
                  The included studies are the most relevant publications to
                  answer the review questions and will be analyzed in a
                  systematic review and meta-analysis.
                </p>
                <p>
                  Examples of protocols include selection strategies in the
                  field of pharmacology <em>(</em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42019123012">
                    <em>Ballard et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42019125868">
                    <em>Zameer et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42019124404">
                    <em>Pettorruso et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42019116363">
                    <em>David et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020210067">
                    <em>Sartim et al., 2020</em>
                  </a>
                  <em>). </em>
                </p>
                <p>
                  <strong>5.4. Planning data extraction</strong>
                </p>
                <p>
                  In this section, the authors describe the approaches to
                  extract qualitative and quantitative data from the studies
                  included in the review. Ideally, this step should be performed
                  in duplicate, with two independent reviewers, to avoid errors.
                  The protocol should also analyze agreements between reviewers
                  (e.g., 
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3900052/"
                  >
                    Cohen Kappa
                  </a>
                  ).
                </p>
                <p>
                  Qualitative data are extracted to evaluate the external
                  (degree of generalization of results) and internal (risk of
                  experimental bias) studies included in the review.
                </p>
                <p>
                  To evaluate the external validity, information related to the
                  characteristics of the studies defined during the preparation
                  of the question using the mnemonic tool is usually extracted.
                  For example, if the mnemonic tool applied was the PICO, you
                  may want to extract and tabulate information about the
                  population studied (e.g., species, age, and gender),
                  pharmacological intervention (e.g., type of compound, drug
                  class, dose, route of administration, duration of treatment),
                  comparison or control (e.g., type of compound, route of
                  administration, duration of treatment), and outcomes (e.g.,
                  method of obtaining, type of metric, unit of measurement,
                  primary analysis).
                </p>
                <p>
                  To evaluate internal validity, the information required by
                  specific tools for assessing the risk of bias is generally
                  extracted from each primary study. In the area of
                  pharmacology, we suggest the use of the following tools:
                </p>
                <p>
                  §{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://mfr.osf.io/render?url=https://osf.io/9mzr2/?direct%26mode=render%26action=download%26mode=render"
                  >
                    RoB-SYRCLE
                  </a>
                  {" "}
                  adapted for evaluation of studies with animal experimentation
                  from the Cochrane. At the{" "}
                  <Link to="/tools-and-resources">
                    Tools &amp; Resources link
                  </Link>
                  we provide a quick guide to its use.
                </p>
                <p>
                  §{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://amstar.ca/Amstar-2.php"
                  >
                    AMSTAR 2
                  </a>
                  , the RoB tool, applies to evaluating the internal quality of
                  systematic reviews, including randomized or non-randomized
                  studies of health interventions.
                </p>
                <p>
                  Numerical data (e.g., mean, standard deviation, sample sizes,
                  number of comparisons, p, F or t values, correlation indices
                  or any other measure, manipulation, or transformation of the
                  data) are necessary for the calculation of effect sizes of
                  each primary study and for conducting the meta-analysis.
                </p>
                <p>
                  Examples include internal quality planning assessment (
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42018109097">
                    <em>Ma et al., 2018</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42019120418">
                    <em>Souza et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42021260343">
                    <em>Carabali et al., 2021</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://doi.org/10.1186/s13643-016-0374-6"
                  >
                    <em>Williamson et al., 2016</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020179825">
                    <em>Azab et al., 2020</em>
                  </a>
                  <em>), </em>
                  and the evaluation of the external quality of studies in
                  pharmacology <em>(</em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020203826">
                    <em>Lino de Oliveira et al., 2020</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020179825">
                    <em>Azab et al., 2020</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020210067">
                    <em>Sartim et al., 2020</em>
                  </a>
                  <em>).</em>
                </p>
                <p>
                  <em></em>
                </p>
                <p>
                  <strong>5.5. Planning Meta-Analysis</strong>
                </p>
                <p>
                  In this protocol, the authors describe the plans to calculate
                  the effect size of each study and meta-analysis.
                </p>
                <p>
                  <strong>5.5.1 Effect size calculations</strong>
                </p>
                <p>
                  Protocols should explain how effect sizes will be calculated
                  (e.g., mean difference, odds ratio) using numerical data
                  extracted from primary studies. In addition, indicators of
                  uncertainty in the size of the effect sizes should be
                  mentioned. In general, the effect sizes are presented with
                  confidence intervals.
                </p>
                <p>
                  Effect sizes were used to estimate the difference between
                  outcome values (i.e., outcome or dependent variable) in
                  experimental groups (e.g., control group versus treated group)
                  or the strength of the relationship between an outcome (i.e.,
                  outcome or dependent variable) and intervention (e.g.,
                  treatment with a drug).
                </p>
                <p>
                  In addition to the type of relationship between variables that
                  one wants to estimate, the type of outcome of interest
                  (continuous? dichotomous?) will also influence the choice of
                  effect size calculation. In addition, the decision on how the
                  estimated effect sizes in primary studies will be combined and
                  the indicators of the uncertainty of the joint effect size
                  (confidence intervals, standard error, standard deviation, and
                  heterogeneity).
                </p>
                <p>
                  <strong>Table 2.</strong>
                  Examples of calculations of effect sizes in differences
                  between independent group means
                </p>
                <p>
                  Notes: Adapted table of
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.researchgate.net/profile/Helena-Espirito-Santo/publication/273143169_Calcular_e_apresentar_tamanhos_do_efeito_em_trabalhos_cientificos_1_As_limitacoes_do_p_005_na_analise_de_diferencas_de_medias_de_dois_grupos_Calculating_and_reporting_effect_sizes_on_scientific_papers/links/54fa10290cf2040df21b1b1c/Calcular-e-apresentar-tamanhos-do-efeito-em-trabalhos-cientificos-1-As-limitacoes-do-p-0-05-na-analise-de-diferencas-de-medias-de-dois-grupos-Calculating-and-reporting-effect-sizes-on-scientific-pap.pdf"

                  >
                    Espírito-Santo and Daniel (2015)
                  </a>
                  . M = mean of each group; SD = standard deviation of each
                  group; n = number of subjects; gl = degrees of freedom (n -1);
                  control = control. The table is based on the following
                  references:
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://pubmed.ncbi.nlm.nih.gov/19565683/"

                  >
                    Cohen (1992)
                  </a>
                  ;
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://psycnet.apa.org/record/2009-05060-012"

                  >
                    Borenstein (2009)
                  </a>
                  ;
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://psycnet.apa.org/record/2011-21220-000"

                  >
                    Cummings (2012)
                  </a>
                  ;
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://psycnet.apa.org/record/1982-00195-001"

                  >
                    Hedges (1981)
                  </a>
                  ;
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3840331/"

                  >
                    Lakens (2013)
                  </a>
                  and
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://books.google.com.br/books?hl=pt-BR&amp;lr=&amp;id=p-aFAwAAQBAJ&amp;oi=fnd&amp;pg=PA231&amp;dq=Parametric+measures+of+effect+size&amp;ots=TWyMQceHVF&amp;sig=9F7Itp3X0DWMlllCr4zSgErD_OY#v=onepage&amp;q=Parametric%20measures%20of%20effect%20size&amp;f=false"

                  >
                    Rosenthal (1994)
                  </a>
                  .
                </p>
                <p>
                  In pharmacology, the size of the effect could be interpreted
                  as the effect of treatment with the drug.
                </p>
                <p>
                  In a hypothetical experiment, pharmacologists could assess the
                  hypothesis that the influence of drug X on a given Y
                  characteristic of a sample of P interest is greater than the
                  influence of control C ("
                  <em>
                    compared to control C, what effect is drug X on measure Y
                    taken in population P
                  </em>
                  ?").
                </p>
                <p>
                  In this case, the calculation of the difference between the Y
                  means obtained in the P sample treated with C or X would
                  indicate the size of the difference between the treatments.
                </p>
                <p>
                  Examples of protocols for planning effect sizes of studies in
                  the field of pharmacology <em>(</em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020203826">
                    <em>Lino de Oliveira et al., 2020</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42021260343">
                    <em>Carabali et al., 2021</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020210067">
                    <em>Sartim et al., 2020</em>
                  </a>
                  <em>). </em>
                </p>
                <p>
                  <strong>5.5.2 Meta-Analysis</strong>
                </p>
                <p>
                  At this stage, the authors should specify, by the outcome,
                  whether a meta-analysis will be conducted, and how this
                  decision will be made. In addition, the protocol should have a
                  description of how the studies will be grouped to calculate
                  the size of the combined effect, which statistical model is
                  appropriate for combining the effect sizes of the primary
                  studies, which indicators of uncertainty will be calculated,
                  and how publication bias will be investigated:
                </p>
                <p>
                  § Meta-analysis: yes or no? The number of studies available
                  for calculation, which are like each other, is a limiting
                  factor for meta-analysis. Theoretically, two studies were
                  sufficient to calculate the joint effect size. However, as
                  with other statistical methods, a meta-analysis can provide
                  inconclusive results with small sample sizes. Therefore,
                  although sample size calculations or statistical power
                  analysis can be performed, the protocol should have a
                  description of these calculations.
                </p>
                <p>
                  § How will the studies be grouped to calculate the size of the
                  joint effect? According to the research area, it is up to the
                  authors to define how similar the studies should be to make up
                  a group or subgroup of studies that will have the joint
                  results. Examples: a meta-analysis will be performed with all
                  studies with the same type of population (or intervention);
                  stratified meta-analysis will be performed with studies
                  divided into subgroups of species/strain/sex/age of
                  experimental animals, types and doses of drugs, or method of
                  obtaining the outcome.
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_13.png"
                    alt="FIG_13"
                  />
                  <figcaption className="figure-caption">
                    Figure 13. Examples of forest graphs made from a
                    meta-analysis using stratified studies in population
                    subgroups (sex or lineage)
                  </figcaption>
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_14.png"
                    alt="FIG_14"
                  />
                  <figcaption className="figure-caption">
                    Figure 14. Examples of forest graphs made from a
                    meta-analysis using stratified studies in intervention
                    subgroups (pharmacological class or drugs)
                  </figcaption>
                </p>
                <p>
                  § Statistical model of meta-analysis: Fixed or random models?
                  Even when investigating the same hypothesis, the primary
                  studies in pharmacology vary in experimental design and ways
                  of doing it. Because of this heterogeneity, random effect
                  models are often eligible for meta-analysis in research fields
                  such as pharmacology.
                </p>
                <p>
                  § What uncertainty indicators of the joint effect size
                  estimate? In general, each joint effect size estimated in a
                  stratified meta-analysis or meta-analysis is accompanied by
                  confidence intervals, standard error, standard deviation, and
                  heterogeneity (e.g., statistics Q, I²).
                </p>
                <p>
                  § How will publication bias be investigated? Publication bias,
                  when planned, can be evaluated using the funnel and{" "}
                  <em>trim-and-fill</em>
                  plotting methods.
                </p>
                <p align="center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_15.png"
                    alt="FIG_15"
                  />
                  <figcaption className="figure-caption">
                    Figure 15. Funnel plotting example
                  </figcaption>
                </p>
                <p>
                  <em>
                    Examples of protocols describing plans for meta-analysis in
                    the field of pharmacology (
                  </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42014009988">
                    <em>Yamato et al., 2014</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42019124804">
                    <em>Soliman et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020203826">
                    <em>Lino de Oliveira et al., 2020</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42021260343">
                    <em>Carabali et al., 2021</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020210067">
                    <em>Sartim et al., 2020</em>
                  </a>
                  <em>).</em>
                </p>
                <p>
                  <em></em>
                </p>
                <p>
                  <em>Suggested references: </em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://training.cochrane.org/handbook/current/chapter-01"
                  >
                    Cochrane Handbook
                  </a>
                  ,
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.bmj.com/content/343/bmj.d4002"
                  >
                    Sterne et al. (2011)
                  </a>
                  ,
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://pubmed.ncbi.nlm.nih.gov/24099992/"
                  >
                    Vesterinen et al. (2014)
                  </a>
                  ,
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://onlinelibrary.wiley.com/doi/book/10.1002/9780470743386"
                  >
                    Borenstein et al. (2009)
                  </a>
                  , and
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://pubmed.ncbi.nlm.nih.gov/31169736/"
                  >
                    <em>Shi and Lin (2019)</em>
                  </a>
                  <em>. </em>
                </p>
                <p>
                  <strong>5.6. Planning the publication of results </strong>
                </p>
                <p>
                  Systematic reviews often involve different employees and large
                  groups of reviewers. It is up to the authors of the protocol
                  to specify, even vaguely, how all taxpayers will receive
                  credit for their work to accommodate the expectations of all
                  authors involved. It is up to the authors to find the means of
                  communication to make the results available to the competent
                  public.
                </p>
                <p>
                  Examples of protocols describe dissemination plans in the
                  field of pharmacology <em>(</em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://doi.org/10.1136/bmjopen-2018-025145"
                  >
                    <em>Kavanagh et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://doi.org/10.1097/MD.0000000000017279"
                  >
                    <em>Lee et al., 2019</em>
                  </a>
                  <em>). </em>
                </p>
                <p>
                  <strong>5.7. Planning the living systematic review</strong>
                </p>
                <p>
                  Finally, pharmacologists may choose whether to keep their
                  systematic reviews and living meta-analyses.
                </p>
                <p>
                  The
                  <a target="_blank" rel="noopener noreferrer" href="https://community.cochrane.org/review-production/production-resources/living-systematic-reviews">
                    Cochrane Collaboration
                  </a>
                  guides the design methods, production, and publication of
                  living systematic reviews in the
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.cochranelibrary.com/cdsr/reviews"
                  >
                    Cochrane Database of Systematic Reviews
                  </a>
                  .
                </p>
                <p>
                  <a target="_blank" rel="noopener noreferrer"
                    href={siteMetadata.siteUrl}


                  >
                    CAMARADES BR
                  </a>{" "}
                  offers a form proposal, adapted from the Cochrane
                  collaboration, for systematic reviews and living meta-analyses
                  in Pharmacology. Download:{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/2xfh4/">
                    HERE
                  </a>
                  .
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#60">
                <h3 className="fw-bold">6 Phase 3: Registering the review protocol</h3>
                <p align="center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_16.png"
                    alt="FIG_16"
                  />
                  <figcaption className="figure-caption">
                    Figure 16. Phase 3: Protocol Registration
                  </figcaption>
                </p>
                <p>
                  <strong>6.1 Public registration platforms</strong>
                </p>
                <p>
                  Registering the protocol before implementing systematic
                  reviews and meta-analyses can help reviewers adhere to the
                  plan. An extensive list of decisions must be made regarding
                  the process, reducing the incidence of biased reviews. It may
                  be necessary to return to the protocol at any stage of the
                  review to find the information necessary to implement the
                  review activities.
                </p>
                <p>
                  Pharmacologists can record their reviews on public platforms,
                  such as PROSPERO and the Open Science Framework (OSF):
                </p>
                <p>
                  §{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.crd.york.ac.uk/prospero/"
                  >
                    PROSPERO
                  </a>
                  : a platform specialized in protocol records for systematic
                  reviews and meta-analyses of studies relevant to human health
                  in humans and laboratory animals
                </p>
                <p>
                  §{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/">
                    Open Science Framework
                  </a>
                  : free public platform used to deposit scientific protocols of
                  any study, including systematic reviews and meta-analysis
                  protocols.
                </p>
                <p>
                  NOTE: Records can also be made in peer-review scientific
                  journals, see examples:
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://dx.doi.org/10.1097/PR9.0000000000000766"
                  >
                    <em>Soliman et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://doi.org/10.1097/MD.0000000000017279"
                  >
                    <em>Lee et al., 2019</em>
                  </a>
                  <em>; </em>
                  <em>Kavanagh et al., 2019</em>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://ri.conicet.gov.ar/handle/11336/103342"
                  >
                    <em>Ramos-Hryb et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020191711">
                    <em>Ang et al., 2020</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://pubmed.ncbi.nlm.nih.gov/32292826/"
                  >
                    Maguire and Guérin, 2020
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/96csg/">
                    <em>Thombs et al., 2020</em>
                  </a>{" "}
                  <em>, </em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8647582/"
                  >
                    <em>Bolzan e Lino de Oliveira, 2021</em>
                  </a>
                  <em>.</em>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#70">
                <h3 className="fw-bold">7 Phase 4: Implementation of the review protocol</h3>
                <p>
                  The review protocol in phase 4 consists of implementing the
                  actions foreseen in the protocol previously elaborated in
                  phase 2 and registered in phase 3).
                </p>
                <p align="center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_17.png"
                    alt="FIG_17"
                  />
                  <figcaption className="figure-caption">
                    Figure 17. Phase 4: implementation of the review protocol
                  </figcaption>
                </p>
                <p>
                  Throughout the implementation process, reviewers need to make
                  complete notes of any decisions made and any deviations from
                  the protocol.
                </p>
                <p>
                  These annotations increase transparency and help ensure that
                  all team members are in line with expectations. In addition,
                  they help review readers judge the degree of trust in the
                  data.
                </p>
                <p>
                  <strong>
                    7.1. Free software, scripts, and other features
                  </strong>
                  <strong></strong>
                </p>
                <p>
                  We identified many free or commercial resources available to
                  implement the processes in a systematic review and
                  meta-analysis that can help pharmacologists. The degrees of
                  automation of these tools vary, but they generally accelerate
                  the activities needed to perform the review.
                </p>
                <p>
                  <strong>7.1.1 Search on bibliographic bases.</strong>
                </p>
                <p>
                  <strong> </strong>
                </p>
                <p>
                  The "advanced search" menu of virtual bibliographic database
                  search engines (e.g., Medline via{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://pubmed.ncbi.nlm.nih.gov/"
                  >
                    Pubmed 
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.scopus.com/home.uri"
                  >
                    Scopus, 
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://access.clarivate.com/login?app=wos&amp;alternative=true&amp;shibShireURL=https:%2F%2Fwww.webofknowledge.com%2F%3Fauth%3DShibboleth&amp;shibReturnURL=https:%2F%2Fwww.webofknowledge.com%2F%3Fmode%3DNextgen%26action%3Dtransfer%26path%3D%252Fwos%252Fwoscc%252Fbasic-search%26DestApp%3DUA&amp;referrer=mode%3DNextgen%26path%3D%252Fwos%252Fwoscc%252Fbasic-search%26DestApp%3DUA%26action%3Dtransfer&amp;roaming=true">
                    Web of Science 
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.embase.com/?phase=continueToApp#search"
                  >
                    Embase 
                  </a>
                  ) is often more appropriate for the type of search required in
                  a Systematic Review than the simple search menu.
                </p>
                <p>
                  Retrieving documents from different virtual bases requires the
                  use of reference management software for deduplication.
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.mendeley.com/download-reference-manager/"
                  >
                    Mendeley
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.zotero.org/">
                    Zotero
                  </a>
                  , and{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.rayyan.ai/">
                    Rayyan
                  </a>{" "}
                  are examples of free managers. CAMARADES developed an {" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.ed.ac.uk/clinical-brain-sciences/research/camarades/tools-resources">
                    automated systematic search duplication tool (ASySD)
                  </a>
                  .
                </p>
                <p>
                  The number of publications obtained in the research conducted
                  in each bibliographic database, before and after
                  deduplication, should be noted in the PRISMA flowchart
                  presented in the final report of the review. The PRISMA
                  flowchart can be downloaded {" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="http://prisma-statement.org/prismastatement/flowdiagram.aspx"
                  >
                    HERE
                  </a>
                  .
                </p>
                <p align="center">
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_18.png"
                    alt="FIG_18"
                  />
                  <figcaption className="figure-caption">
                    Figure 18. Example of a filled PRISMA flowchart
                  </figcaption>
                </p>
                <p>
                  <strong>7.1.2 Screening of relevant studies</strong>
                </p>
                <p>
                  Reference management software is also helpful for applying the
                  selection and eligibility criteria of the relevant studies.
                  For example,{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.mendeley.com/download-reference-manager/"
                  >
                    Mendeley
                  </a>
                  and{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.rayyan.ai/">
                    Rayyan
                  </a>{" "}
                  can semi-automatically sort references in a library.
                </p>
                <p>
                  Other free resources are{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://asreview.nl/">
                    ASReview
                  </a>{", "}
                  <a target="_blank" rel="noopener noreferrer" href="https://parsif.al/">
                    Parsifal
                  </a>{", "}
                  <a target="_blank" rel="noopener noreferrer" href="https://revtools.net/">
                    Rvtools
                  </a>{" "}
                  (tools for r-evidence synthesis),
                  <a target="_blank" rel="noopener noreferrer" href="https://sysrev.com/">
                    Sysrev
                  </a>
                  , Screenatron, and Systematic Review Accelerator, and{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://syrf.org.uk/">
                    SyRF
                  </a>{" "}
                  (CAMARADES).
                </p>
                <p>
                  The results of the screening processes (number of excluded
                  studies, exclusion reasons, number of studies included) should
                  be noted in a PRISMA flowchart presented in the final report
                  of the review. The PRISMA flowchart can be downloaded
                  {" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="http://prisma-statement.org/prismastatement/flowdiagram.aspx"
                  >
                    HERE
                  </a>
                  .
                </p>
                <p>
                  <strong>7.1.3 Data extraction</strong>
                </p>
                <p>
                  Reference managers (
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://www.mendeley.com/download-reference-manager/"
                  >
                    Mendeley
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.zotero.org/">
                    Zotero
                  </a>
                  , and{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.rayyan.ai/">
                    Rayyan
                  </a>
                  ) can obtain bibliographic information from the included
                  studies (e.g., author name, year, journal). Online tools, such
                  as{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://colandrcommunity.com/"
                  >
                    Colandr
                    {" "}
                  </a>
                  and{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://syrf.org.uk/">
                    SyRF
                  </a>
                  , help annotate, tabulate, share, and manage qualitative and
                  numerical data extracted from publications more efficiently.
                </p>
                <p>
                  There are free versions of tools such as
                  {" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://markummitchell.github.io/engauge-digitizer/"
                  >
                    Engauge Digitizer
                  </a>
                  {" or "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://automeris.io/WebPlotDigitizer/"
                  >
                    WebPlotDigitizer
                  </a>
                  {" "}
                  that convert images (from charts or maps) into numbers. In
                  addition, manual measurements of graphics elements (scale or
                  bar sizes or line extensions and other attributes) are
                  possible using the digital ruler of PDF readers (for example,
                  free version of Adobe reader).
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_19.png"
                    alt="FIG_19"
                  />
                  <figcaption className="figure-caption">
                    Figure 19. Data extraction from included studies using
                    digital ruler
                  </figcaption>
                </p>
                <p>
                  Some programming language knowledge allows users to use the
                  {" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://cran.r-project.org/web/packages/metaDigitise/index.html"
                  >
                    metaDigitise R
                  </a>
                  and
                  {" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://cran.r-project.org/web/packages/metagear/metagear.pdf"
                  >
                    metagear
                  </a>{" "}
                  package to extract descriptive statistics. Tables in CSV
                  format can be formatted for use in future meta-analyses (
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/rmqe3/">
                    Table 1
                  </a>
                  ).
                </p>
                <p>
                  The{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://cran.r-project.org/web/packages/pacman/index.html"
                  >
                    pacman
                  </a>
                  {" "}
                  package is an R-pack management tool, which combines
                  {" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://www.rdocumentation.org/packages/dplyr/versions/0.7.8"
                  >
                    dplyr
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://cran.r-project.org/web/packages/irr/irr.pdf"
                  >
                    irr,
                  </a>
                  {" "}and{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://cran.r-project.org/web/packages/rel/index.html"
                  >
                    rel
                  </a>
                  {" "}
                  functionality, used for matching calculations between
                  reviewers (
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3900052/"
                  >
                    Cohen Kappa
                  </a>
                  ) whenever two independent reviewers perform an activity. We
                  provide the table models here at SysPhar .csv for analysis of
                  agreement between reviewers (
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/k7yng/">
                    Table 2
                  </a>
                  ) and scripts for the{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/z93qr/">
                    Pacman
                  </a>{" "}
                  tool.
                </p>
                <p>
                  <strong>
                    7.1.4 Bias risk assessment using the RoB-Syrcle tool
                  </strong>
                </p>
                <p>
                  The presentation of the data from the RoB-Syrcle tool can be
                  facilitated by the Robvis tool (
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://onlinelibrary.wiley.com/doi/10.1002/jrsm.1411"
                  >
                    McGuinness and Higgins, 2020
                  </a>
                  ). Here, we provide the SysPhar .csv table models for risk
                  assessment of bias (
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/ruscj/">
                    Table 3
                  </a>
                  ) and scripts for the use of the{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/ztmyr/">
                    Robvis
                  </a>{" "}
                  tool.
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_20.png"
                    alt="FIG_20"
                  />
                  <figcaption className="figure-caption">
                    Figure 20. Example of presenting the results of the bias
                    risk assessment using the RoB
                  </figcaption>
                </p>
                <p>
                  Legend: The column on the left corresponds to the signaling
                  questions. The legend corresponds to the judgments "low risk
                  of bias" (green), "risk of uncertain bias" (yellow) and "high
                  risk of bias" (red). Simulation data (R Studio, robvis
                  package).
                </p>
                <p>
                  <strong>7.1.5. Meta-analysis calculation</strong>
                </p>
                <p>
                  Tools such as
                  {" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://www.erim.eur.nl/research-support/meta-essentials/"
                  >
                    Meta-essentials
                  </a>
                  ,
                  <a target="_blank" rel="noopener noreferrer" href="https://training.cochrane.org/online-learning/core-software-cochrane-reviews/revman">
                    RevMan
                  </a>
                  {" "}, or{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="http://www.cebm.brown.edu/openmee/"
                  >
                    OpenMEE
                  </a>{" "}
                  can be used to conduct a meta-analysis for free by reviewers
                  unfamiliar with statistical software or programming language.
                </p>
                <p>
                  Reviewers familiar with coding can benefit from software such
                  as{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://cran.r-project.org/web/packages/meta/index.html"
                  >
                    Meta
                  </a>
                  or
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://cran.r-project.org/web/packages/metafor/index.html"
                  >
                    Metafor
                  </a>
                  packages and scripts in R,{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.python.org/">
                    Python
                  </a>
                  , or                   {" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="http://www.cebm.brown.edu/openmeta/download.html"
                  >
                    OpenMeta [Analista]
                  </a>.
                  For example, the
                  {" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://cran.r-project.org/web/packages/metagear/index.html"
                  >
                    metagear
                  </a>
                  {" "}
                  package for R is a free resource that, in addition to
                  facilitating data sorting and extraction, is also used for
                  meta-analysis.
                </p>
                <p>
                  Here, we provide SysPhar script templates for {" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/r79jw/">
                    metafor
                  </a>
                  {" "}
                  (meta-analysis, publication bias) in addition to {" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/759jm/">
                    metapower
                  </a>
                  {" "}
                  for calculating statistical power, if applicable.
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_21.png"
                    alt="FIG_21"
                  />
                  <figcaption className="figure-caption">
                    Figure 21. . Example of forest graph (meta-analysis)
                  </figcaption>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#80">
                <h3 className="fw-bold">Phase 5: Publication of the results of the review</h3>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_22.png"
                    alt="FIG_22"
                  />
                  <figcaption className="figure-caption">
                    Figure 22. Phase 5: Publishing the results of the review
                    <strong></strong>
                  </figcaption>
                </p>

                <p>
                  <strong>9.1 Where and how to publish?</strong>
                </p>
                <p>
                  There are different strategies for publishing, sharing, and
                  disseminating review results, including scientific events,
                  workshops, online platforms, and peer-reviewed journals.
                </p>
                <p>
                  In peer-reviewed journals, except for some specifications, the
                  systematic review and meta-analysis report can be organized as
                  other types of publications. Some journals request articles to
                  submit a{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="http://www.prisma-statement.org/"
                  >
                    PRISMA checklist
                  </a>{" "}
                  to facilitate peer review.
                </p>
                <p>The publication can be organized as follows:</p>
                <p>
                  § <strong>Introduction</strong>: theoretical history,
                  hypothesis, and the question of review.
                </p>
                <p>
                  § <strong>Methods:</strong> the protocol registration number,
                  research strategy, screening strategy, data extraction,
                  quality assessment, and analysis are briefly presented.
                  Important: Calculations and interpretations of effect sizes
                  must be explicitly stated because they affect the data
                  discussion.
                </p>
                <p>
                  § <strong>Results:</strong> description of the results of the
                  search and screening processes of studies, presenting the:{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="http://www.prisma-statement.org/"
                  >
                    PRISMA checklist
                  </a>{" "}
                  as a figure of the article; description of the qualities of
                  the publications included in the review and systematization of
                  the results in tables (for the discussion of the external
                  validity of the studies); description of the results of the
                  bias risk analysis of the studies included in the review (for
                  the discussion of the internal validity of the studies);
                  description of the numerical results of the meta-analysis
                  (estimates of overall combined effect size with or without
                  correction by publication bias, estimates of combined effect
                  size stratified by subgroups, heterogeneity of estimates) and
                  graphical presentation, in general, the forest graph is used
                  for meta-analysis and funnel graphs when evaluating the
                  publication bias.
                </p>
                <p>
                  § <strong>Discussion:</strong> the evaluation of the internal
                  and external quality of the publications included should be
                  addressed; meta-analysis results (the direction, size, and
                  heterogeneity of combined effect estimates), impact of the
                  quality of studies, the risk of experimental biases, the risk
                  of publication bias, and the heterogeneity of studies on the
                  estimates of the size of the combined effect.
                </p>
                <p>
                  § <strong>Conclusion:</strong> the reliability of the review
                  results according to the evaluations of quality,
                  heterogeneity, publication bias, and limitations of the review
                  process can be disclosed.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#90">
                <h3 className="fw-bold">9 Phase 6: Keeping living systematic review</h3>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_23.png"
                    alt="FIG_23"
                  />
                  <figcaption className="figure-caption">
                    Figure 23. Phase 6: reviews living<strong></strong>
                  </figcaption>
                </p>

                <p>
                  <strong>9.1 Update and publish</strong>
                </p>
                <p>
                  The existing guidelines guide live systematic reviews to be
                  published according to models like the Cochrane publications.
                </p>
                <p>The available templates are as follows:</p>
                <p>
                  § New publications with each new update (usually done at
                  annual intervals), obtaining a recent version, and DOI in
                  PubMed.{" "}
                  <em>
                    NOTE: This practice is likely to result in numerous
                    publications
                  </em>
                  .
                </p>
                <p>
                  § A publication where the introduction and methods of the main
                  manuscript have not changed, only the results section has
                  changed.
                  <em>
                    NOTE: The manuscript will require fewer resources to prepare
                    in the second model, but the original version must have been
                    written generically to accommodate new emerging information
                    in subsequent versions
                  </em>
                  .
                </p>
                <p>
                  § Publications in which the findings of the updated analyses
                  can be presented as new appendices, without alteration in the
                  original abstract or manuscript.
                  <em>
                    NOTE: This model makes it difficult to add or remove authors
                    as changes in their contribution to subsequent versions
                  </em>
                  .
                </p>
                <p>
                  § Alternatively, the model in which the results of updates are
                  summarized in public domain data repositories, from which they
                  present the results updated visually.{" "}
                  <em>
                    NOTE: Ideally, these repositories should be interactive
                    (e.g.,{" "}
                  </em>
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://abannachbrown.shinyapps.io/preclinical-models-of-depression/"

                  >
                    <em>app RShiny</em>
                  </a>
                  <em>). </em>
                </p>
                <p>
                  When no recent studies are identified for inclusion, only
                  published research data must be updated. However, if further
                  studies are found, a new publication is needed.
                </p>
                <p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/en/sysphar/FIG_24.png"
                    alt="FIG_24"
                  />
                  <figcaption className="figure-caption">
                    Figure 24. Phase 6: Flow of actions for systematic reviews
                    and living meta-analyses
                  </figcaption>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#100">
                <h3 className="fw-bold">10 About</h3>
                <h3>10.1 SysPhar</h3>
                <p>
                  SysPhar (<em>Systematic Pharmacology</em>) is a resource
                  produced by CAMARADES BR.
                </p>
                <p>
                  In this SysPhar guide, we gather and organize information,
                  documents, links, and other tools that we find helpful in
                  helping pharmacologists perform their systematic reviews and
                  meta-analysis in pharmacology.
                </p>
                <p>
                  The resources presented in SysPhar are publicly available on
                  the pages of organizations such as CAMARADES, Cochrane, OSF,
                  PROSPERO, SYRCLE, PRISMA, and others. We thank these
                  organizations and teams for making these resources publicly
                  available and for free!
                </p>
                <p>This feature was last updated on: August 07th, 2023.</p>
                <p>
                  <h3>10.2 How to quote the tool?</h3>
                </p>
                <p className="font-monospace">
                  Sysphar. Systematic Pharmacology (January 2022), CAMARADES,
                  Brazil. {getENCitation()}
                </p>

                <h3 id="our-team">10.3 Our Team</h3>
                <ul>
                  <li>Juliana Aparecida Bolzan, Ma.</li>
                  <li>Cilene Lino de Oliveira, PhD.</li>
                  <li>Tamires Martins, Ma.</li>
                  <li>Sofia Diehl Döring, PharmB.</li> 
                </ul>

                <Link to="/en/about-camarades#supporters">
                  <h3>10.4 Supporters</h3>
                </Link>

                <h3>10.5 Contact</h3>
                <p>
                  If you have questions about the features or would you like to
                  ask a specific question, would you like to contribute to the
                  text?
                </p>
                <p> <Link to="/en/contact">Contact us!</Link></p>
              </Tab.Pane>
            </Tab.Content>
          </Col>
          <Col sm={1}></Col>
        </Row>
      </Tab.Container>
    </section>
  )
}
