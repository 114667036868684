import * as React from "react"
import GradientJumbo from "../../components/gradient-jumbo"
import Layout from "../../components/layout"
import Contact from "../../components/sections/contact"
import SyspharTabs from "../../components/sections/sysphar/sysphar_tabs_en"
import Seo from "../../components/seo"

const SyspharPage = props => (
  <Layout language="en" pathname={props.location.pathname}>
    <Seo
      title="SysPhar"
      lang="en"
      description="This SysPhar Guide contains helpful information, links, articles, resources, and tools to conduct your systematic review and meta-analysis"
    />
    <GradientJumbo subtitle="SysPhar" title="A guide for Pharmacologists" />
    <SyspharTabs language="en" />
    <Contact language="en" />
  </Layout>
)

export default SyspharPage
